:root {
	--color-blue: #4D6A68;
	--color-yellow: #9D7C42;
	--color-red: #B53F29;
	--color-gray: #BCBCBC;
	--color-darkGray: #2D2B2A;
	--color-wbmc-yellow: #f1f900;
	
	--menuHeight: 150px;

	@media screen and (min-width: 850px) and (max-height: 850px) {
		--menuHeight: 80px;
	}
	
	
	@media screen and (max-width: 950px) {
		--menuHeight: auto;
	}
	
	
	--wrapWidth: 1300px;
	
	--headerart: url(./assets/img/headart.png);
	--logo: url(./assets/img/logo.svg);
	--menu: url(./assets/img/menucorner.png);
	--discord: url(./assets/img/discord.svg);
	--twitter: url(./assets/img/twitter.svg);
	--label: url(./assets/img/label.svg);
	--rust: url(./assets/img/rust.png);
	--countbg: url(./assets/img/countbg.svg);
	--greenpers: url(./assets/img/greenpers.png);
	--audiobg: url(./assets/img/audiobg.png);
	--eq: url(./assets/img/eq.svg);
	--interior: url(./assets/img/interior.jpg);
	--footer: url(./assets/img/footer.png);
	--gamebg: url(./assets/img/gamebg.jpg);
	--gamebgmob: url(./assets/img/gamebgmob.jpg);
	--splash: url(./assets/img/splash.jpg);
	--play: url(./assets/img/play.svg);
	
	--team1: url(./assets/img/team_1.png);
	--team2: url(./assets/img/team_2.png);
	--team3: url(./assets/img/team_3.png);
	--team4: url(./assets/img/team_4.png);
	--team5: url(./assets/img/team_5.png);
	--team6: url(./assets/img/team_6.png);
	--team7: url(./assets/img/team_7.png);
	--team8: url(./assets/img/team_8.png);
	--team9: url(./assets/img/team_9.png);
	--team10: url(./assets/img/team_10.png);
	--team11: url(./assets/img/team_11.png);
	
}



@font-face {
	font-family: 'custom';
	src: url(./assets/fonts/custom.ttf) format('truetype');
	font-display: swap;
}


* {
	box-sizing: border-box;
}

#root {
	position: relative;
	height: 100%;
	min-height: 100vh;
}

html, body {
	position: relative;
	// height: 100%;
	min-height: 100vh;
	scroll-behavior: smooth;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	color: var(--color-gray);
	// scroll-snap-type: y mandatory;
	overflow-x: hidden;
}

html {
	background-color: var(--color-darkGray);
	background-image: url(./assets/img/bg.jpg);
	background-repeat: no-repeat;
	background-position: 0 100%;
	background-size: cover;
	background-attachment: fixed;
	
	&:before {
		content:'';
		background-image: url(./assets/img/headvynil.png);
		background-repeat: no-repeat;
		background-position: 100% 0%;
		background-size: auto 450px;
		position: fixed;
		top: 0;
		width: 100%;
		height: 100%;
		
		@media screen and (max-width: 950px) {
			background-size: auto 200px;
			background-position: 100% 0%;
		}
		
	}
}

body {
	background: none;
	&:before {
		content:'';
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		top: var(--menuHeight);
		background-image: url(./assets/img/shadowbg.svg);
		background-repeat: no-repeat;
		background-position: 0% 0%;
		background-size: cover;
		right: 0;
	}
}

section {
	z-index: 1;
	position: relative;
	min-height: 100vh;
	padding-top: var(--menuHeight);
	display: flex;
	align-items: center;
	
	&.short {
		min-height: auto;
		padding-top: 0;
		display: block;
	}
}

p {
	font-size: 20px;
	line-height: 120%;
	margin-top: 1em;
	
	a {
		font-family: custom;
		color: var(--color-red);
		font-size: 2em;
		text-decoration: underline;
		
		&:hover {
			text-decoration: none;
		}
	}
}

.wrap {
	position: relative;
	margin: 0 auto;
	width: 95%;
	max-width: var(--wrapWidth);
	
	&.expanded {
		@media screen and (max-width: 950px) {
			width: 100%;
		}
	}
}

.page-content {
	z-index: 1;
    position: relative;
    min-height: 100vh;
    padding-top: 100px;
    display: flex;
    //align-items: center;
	//text-align: center;

	@media screen and (min-width: 600px) {
		padding-top: 120px;
	}

	@media screen and (min-width: 950px) {
		padding-top: calc(var(--menuHeight) + 40px);
	}

	@media screen and (min-width: 1300px) {
		padding-top: calc(var(--menuHeight) + 70px);
	}
}

.page-content-container {
	width: 96%;
	max-width: 100%;
	margin: 0 2%;
	text-align: center;

	@media screen and (min-width: 950px) {
		width: 100%;
		max-width: 950px;
		margin: 0 auto;
	}

	@media screen and (min-width: 1100px) {
		max-width: 1100px;
	}

	@media screen and (min-width: 1300px) {
		max-width: 1260px;
	}
}

.page-content-container h1, .page-content-container h2, .page-content-container h3, .page-content-container h4 {
    font-family: custom;
    color: #fff;
    line-height: 90%;
	margin-top: 20px;
}

.page-content-container h1 {
	font-size: clamp(35px, 6vmax, 80px);
}

.page-content-container h2 {
	font-size: clamp(35px, 6vmax, 55px);
}

.page-content-container h3 {
	font-size: clamp(35px, 6vmax, 45px);
}

.page-content-container h4 {
	font-size: clamp(35px, 6vmax, 30px);
}

.page-content-container p {
	color: #ffffff;
	font-size: 1.2em;
}

.page-content-container p a {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 1.1em;
}