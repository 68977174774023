.footer {
	margin-top: 2em;
	min-height: 100px;
	background-image:  var(--footer);
	background-repeat: repeat-x;
	background-position: 0 0;
	background-size:auto 110%;
	width: 100%;
	display: flex;
	align-items: center;
}

.menu {
	color: var(--color-red);
	font-size: 30px;
	font-family: custom;
	padding: .5em;
	border-radius: 0 10px 0 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	line-height: 100%;
	gap: 10px 0; /* row-gap column gap */

	li {
		&:not(:last-child):after {
			content: '|';
			color: var(--color-red);
			padding: 0 .2em;
		}

	}

	a {
		color: #fff;
		text-decoration: none;

		&:hover {
			color: var(--color-red);
		}
	}
}